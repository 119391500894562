<template>
  <div class="dashboard p-5" id="dementie-information">
    <div class="row">
      <div class="col-sm-12 down">
        <h1 class="intro-section-title">Leven met Dementie - Wonen</h1>
        <div class="row">
          <p class="intro-section-text">
            Dit dashboard geeft informatie over het onderwerp <b>wonen</b> als onderdeel van de levenspadfase <b>'Leven met Dementie'</b>. 
          </p>
          <!-- <p class="intro-section-text">
           Het doel van dit dashboard is om te laten zien waar mensen met dementie wonen en onder welke omstandigheden dit thuis kan zijn. Netwerken kunnen aan de hand van deze informatie hun beleid aanpassen om een goede mix van woonsituaties te creëren, passend bij de demografische gegevens van mensen met dementie binnen het netwerk. Er wordt een vergelijking gemaakt tussen de netwerken om te zien op welk gebied netwerken van elkaar kunnen leren. Daarnaast wordt er een prognose gedaan van het aantal verschillende woonplekken richting de toekomst zodat netwerken hierop kunnen anticiperen.
          </p> -->
        </div>
      </div>
    </div>

      <!-- <div class="col-sm-6">
        <card :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database'">
          <template slot="card-header">CONCEPT Woonsituatie mensen met Dementie</template>
          <div slot="card-body">
            In deze staafdiagram is de verhouding te zien tussen thuiswonende mensen, mensen wonend in een kleinschalig wooninitiatief of in een regulier verpleeghuis.
              <stacked-bar-chart :data1="[{ type: 'bar', name:'Thuiswonend',
              x: [2018,2019,2020,2021], y:[55,55,60,50], marker: {
              color: 'rgba(180,180,180,1.00)'}},{ type: 'bar', name: 'Kleinschalig Wooninitiatief',
              x: [2018,2019,2020,2021], y:[26,23,20,30], marker: {
              color: 'rgba(180,180,180,0.80)'}},{ type: 'bar', name: 'Regulier verpleeghuis',
              x: [2018,2019,2020,2021], y:[19,22,20,20], marker: {
              color: 'rgba(180,180,180,0.60)'}}]"/>
          </div>
        </card>
      </div> -->
<!--         <div class="col-sm-6">
        <card-large :hovertext="''">
          <template slot="card-header">Woonzorgorganisaties</template>
            <div slot="card-body">
              Op deze kaart van Nederland zie je de Woonzorgorganisaties.
            <mapbox v-if='load.graphTwo' :zoom="6.5" :height="850" :lat="lat" :lon="lon" :text="zorglocaties"/> -->
            <!-- <div> {{selectedLevel}} </div>
            <div> {{selectedRegion}} </div> -->
<!--            </div>
        </card-large>
      </div> -->


  <div class="row">
    <div class="col-sm-12" v-if="selectedLevel !=='landelijk'">
      <card :cardId="'Aantal mensen met dementie per beschikbaar bed voor verpleeghuiszorg-1'" :hovertext="'Mensen met dementie zijn ingedeeld naar zorgkantoorregio op basis van hun postcode. De bron voor het aantal verpleegbedden per regio is afkostig van TNO: https://open.overheid.nl/repository/ronl-e7569fab-4e43-42aa-b29a-948ae34cd87d/1/pdf/tno-rapport-prognose-capaciteitsontwikkeling-verpleeghuiszorg.pdf. '" :x="zorgkantoorregionamen" :y="verhoudingen.map(value => `${value}`)" 
        :xtitle="'Regio naam'" :ytitle="'Aantal'"  :tableHeight='900'>
        <template slot="card-header">Aantal mensen met dementie per beschikbaar bed voor verpleeghuiszorg</template>
          <div slot="card-body">
              Deze grafiek toont het aantal mensen met dementie per beschikbaar bed in de verpleeghuiszorg per zorgkantoorregio.

              <horizontal-bar-chart v-if="load.graphOne" :x="verhoudingen" :y="zorgkantoorregionamen" :height="800" :marginleft='200' :title="'Alle regio`s'" :range_value="0.1" :width="600"/>

          </div>
      </card>
    </div>

    <div class="col-sm-12" v-if="selectedLevel ==='landelijk'">
      <card :cardId="'Aantal mensen met dementie per beschikbaar bed voor verpleeghuiszorg-2'" :hovertext="'Mensen met dementie zijn ingedeeld naar zorgkantoorregio op basis van hun postcode. De bron voor het aantal verpleegbedden per regio is afkostig van TNO: https://open.overheid.nl/repository/ronl-e7569fab-4e43-42aa-b29a-948ae34cd87d/1/pdf/tno-rapport-prognose-capaciteitsontwikkeling-verpleeghuiszorg.pdf. '" >
        <template slot="card-header">Aantal mensen met dementie per beschikbaar bed voor verpleeghuiszorg</template>
          <div slot="card-body">
              Deze grafiek toont het aantal mensen met dementie per beschikbaar bed in de verpleeghuiszorg per zorgkantoorregio.

              <horizontal-bar-chart v-if="load.graphThree" :x="verhoudingen" :y="zorgkantoorregionamen" :height="800" :marginleft='200' :title="'Alle regio`s'" :range_value="0.1" :width="600" :isLandelijk="false"/>

          </div>
      </card>
    </div>
  </div>

  <div class="row"> 
    <div class="col-sm-12">    
    <card :cardId="'Aantal mensen met dementie t.o.v. verpleeghuisplekken'" :hovertext="'Mensen met dementie zijn ingedeeld naar zorgkantoorregio op basis van hun postcode. De bron voor het aantal verpleegbedden per regio is afkostig van TNO: https://open.overheid.nl/repository/ronl-e7569fab-4e43-42aa-b29a-948ae34cd87d/1/pdf/tno-rapport-prognose-capaciteitsontwikkeling-verpleeghuiszorg.pdf. '" 
      :x="zorgkantoorregionamen" :y="verhoudingen.map(value => `${value}`)" :xtitle="'Regio namen'" :ytitle="'Aantal'" :tableHeight='800'>
      <template slot="card-header">Aantal mensen met dementie t.o.v. verpleeghuisplekken</template>
        <div slot="card-body">
            <choropleth  v-if="load.graphTwo" :zoom="6.3" :height="860" :colorscale="colorscale" :zmax='2.1' :zmin="1.1" :z="factors" :locations="location_factors" :text="location_names" :colorbar="{ ticksuffix: '' }" :hovertemplate= "''"/>

          </div>
        </card>
    </div>
  </div>

        <!-- <div class="col-sm-6">
          <card :hovertext="'De data die wordt gebruikt voor deze grafiek komt uit de CBS database'">
            <template slot="card-header">CONCEPT Benodigde groei/krimp verzorgingstehuisplekken o.b.v. demografie</template>
            <div slot="card-body">
              In onderstaand diagram is te zien hoe het aantal plekken in verschillende woonsituaties zich tot elkaar verhouden. Daarnaast is er een prognose van het aantal plekken in de verschillende vormen over tijd.
                <stacked-bar-chart :data1="[{ type: 'bar', name: 'Thuiswonend',
                x: [2018,2019,2020,2021, 2022, 2025], y:[6100,6050,5900,5600,5300,4900], text: [6100,6050,5900,5600,5300,4900] , textposition: 'auto', hoverinfo: 'none', marker: {
                color: 'rgba(180,180,180,1.0)'}},{ type: 'bar', name: 'Kleinschalig Wooninitiatief',
                x: [2018,2019,2020,2021, 2022, 2025], y:[3420,4180,4000,4250,4600,5000], text: [3420,4180,4000,4250,4600,5000] , textposition: 'auto', hoverinfo: 'none', marker: {
                color: 'rgba(180,180,180,0.80)'}},{ type: 'bar', name: 'Regulier verpleeghuis',
                x: [2018,2019,2020,2021, 2022, 2025], y:[6000,5950,6100,5800,5500,5000], text: [6000,5950,6100,5800,5500,5000] , textposition: 'auto', hoverinfo: 'none', marker: {
                color: 'rgba(180,180,180,0.60)'}}]"/>
            </div>
          </card>
        </div> -->
    

      <div class="row">
        <!-- <div class="col-sm-12">
          <card :hovertext="'Het percentage thuiswonende wordt berekend als het aantal mensen dat thuis woont ten opzichte van de gehele populatie geïdentificeerde mensen met dementie. De gemiddelde SES wordt afgeleid uit de CBS data en berekent voor de mensen met dementie die thuis wonen. Het percentage potentiële mantelzorger wordt bepaald door te kijken naar gegevens over een inwonende partner of in de buurt wonende kinderen (binnen een straal van 10km).'">
            <template slot="card-header">CONCEPT Thuiswonend</template>
              <div slot="card-body">
                Deze horizontale staafdiagrammen geven informatie over mensen met dementie die thuis wonen. In de linker grafiek wordt per regio het percentage weergegeven van mensen met dementie die thuis wonen. Rechts daarvan wordt een verdere verdieping van data over deze groep thuiswonende mensen weergegeven. Voorbeelden zijn de gemiddelde leeftijd van de thuiswonenden, het aantal uren verzorging thuis, de gemiddelde sociaal-economische status (SES) en hoeveel procent van de thuiswonenden een potentiele mantelzorger in de buurt heeft wonen.
                <div class="row">
                  <div class="col-sm-3">
                  <horizontal-bar-chart :x="[20,25,40,50,52,52,53,60,70,71]"
                  :y="['Achterhoek', 'Midden-Groningen', 'Twente', 'Ooststellingwerf', 'Hollands Kroon', 'Boekel', 'Zuidplas', 'Haarlemmermeer', 'Tilburg', 'Utrecht']" :title="'% Thuiswonend'"/>
                  </div>
                  <div class="col-sm-2">
                  <horizontal-bar-chart :x="[87,73,82,75,80,75,85,80,70,71]"
                  :y="['Achterhoek', 'Midden-Groningen', 'Twente', 'Ooststellingwerf', 'Hollands Kroon', 'Boekel', 'Zuidplas', 'Haarlemmermeer', 'Tilburg', 'Utrecht']" :marginleft="5" :ticklabels="false" :title="'Gemiddelde Leeftijd'"/>
                  </div>
                  <div class="col-sm-2">
                  <horizontal-bar-chart :x="[20,25,40,30,31,20,45,60,40,70]"
                  :y="['Achterhoek', 'Midden-Groningen', 'Twente', 'Ooststellingwerf', 'Hollands Kroon', 'Boekel', 'Zuidplas', 'Haarlemmermeer', 'Tilburg', 'Utrecht']" :marginleft="5" :ticklabels="false" :title="'Uren verzorging Thuis'"/>
                  </div>
                  <div class="col-sm-2">
                  <horizontal-bar-chart :x="[7,8,5,8,9,4,7,9,6,7]"
                  :y="['Achterhoek', 'Midden-Groningen', 'Twente', 'Ooststellingwerf', 'Hollands Kroon', 'Boekel', 'Zuidplas', 'Haarlemmermeer', 'Tilburg', 'Utrecht']" :marginleft="5" :ticklabels="false" :title="'Gemiddelde SES'"/>
                  </div>
                  <div class="col-sm-2">
                  <horizontal-bar-chart :x="[70,80,60,80,80,50,60,80,70,75]"
                  :y="['Achterhoek', 'Midden-Groningen', 'Twente', 'Ooststellingwerf', 'Hollands Kroon', 'Boekel', 'Zuidplas', 'Haarlemmermeer', 'Tilburg', 'Utrecht']" :marginleft="5" :ticklabels="false" :title="' % Potentiële mantelzorger'"/>
                  </div>
                </div>
              </div>
          </card>
        </div> -->
      </div>

      <Footer :adclogo="adclogo" :erasmuslogo="erasmuslogo"/>
  </div>
</template>

<script>

import Card from '../components/Card'
// import StackedBarChart from '../components/StackedBarChart'
import HorizontalBarChart from '../components/HorizontalBarChartNumbers'
// import HorizontalBarChartFixedRange from '../components/HorizontalBarChartFixedRange'
import Choropleth from '../components/Choropleth'
// import Mapbox from '@/components/Mapbox'
import { mapGetters } from 'vuex'
import Footer from '../components/Footer'
import adclogo from '@/assets/ADC full logo.png'
import erasmuslogo from '@/assets/erasmuslogo.png'
import VueMeta from 'vue-meta'

export default {
  mixins: [VueMeta],
  metaInfo: {
    title: 'Leven met Dementie - Wonen',
  },
  components: { Card, HorizontalBarChart, Choropleth, Footer},
  data () {
    return {
      adclogo: adclogo,
      erasmuslogo: erasmuslogo,
      zorgkantoorregionamen: [],
      location_names: [],
      location_factors: [],
      verhoudingen: [],
      factors: [],
      zorglocaties: [],
      lat: [],
      lon: [],
      locations: [],
      load: {
        graphOne: false,
        graphTwo: false,
        graphThree: false
      },
      colorscale: [
        ['0.0', 'rgb(205, 230, 246)'],
        ['0.25', 'rgb(115, 182, 224)'],
        ['0.50', 'rgb(37, 116, 169)'],
        ['0.75', 'rgb(16, 63, 105)'],
        ['1.0', 'rgb(20, 66, 115)']
      ],
    }
  },
  watch: {
    '$store.state.selectedLevel': function (level) {
      this.loadAllData(level)
    },
    '$store.state.selectedYear': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    },
    '$store.state.selectedRegion': function () {
      this.loadAllData(this.$store.state.selectedLevel)
    }
  },
  computed: {
      ...mapGetters(['selectedRegion', 'selectedLevel', 'selectedYear'])
  },
  methods: {
    loadAllData (level) {
      if (level === 'zorgkantoor' || level === 'dementienetwerk') {
        this.$http.get('/api/zorgkantoor_verpleeghuisplekken/get_data/').then(
          (response) => {
            this.zorgkantoorregionamen = response.data.map(d => d.zorgkantoorregionaam)
            this.verhoudingen = response.data.map(d => d.verhouding)
            this.load.graphOne = true
            }
          ),
          this.$http.get('/api/zorgkantoor_verpleeghuisplekken/get_data_gemeentecode/').then(
            (response) => {
              this.location_factors = response.data.map(d => d.gemeentecode)
              this.location_names = response.data.map(d => d.zorgkantoorregionaam)
              this.factors = response.data.map(d => (d.verhouding).toFixed(2))
              this.load.graphTwo = true
            }
          )
      }
      if (level === 'landelijk') {
        this.$http.get('/api/zorgkantoor_verpleeghuisplekken/get_data/').then(
          (response) => {
            this.zorgkantoorregionamen = response.data.map(d => d.zorgkantoorregionaam)
            this.verhoudingen = response.data.map(d => d.verhouding)
            this.load.graphThree = true
            }
          ),
          this.$http.get('/api/zorgkantoor_verpleeghuisplekken/get_data_gemeentecode/').then(
            (response) => {
              this.location_factors = response.data.map(d => d.gemeentecode)
              this.location_names = response.data.map(d => d.zorgkantoorregionaam)
              this.factors = response.data.map(d => (d.verhouding).toFixed(2))
              this.load.graphTwo = true
            }
          )
      }
    }
  },
  mounted () {
      this.loadAllData(this.selectedLevel)
    }
  // mounted () {
  //       this.$http.get('/api/zorgkantoor_verpleeghuisplekken/get_data/').then(
  //         (response) => {
  //           this.zorgkantoorregionamen = response.data.map(d => d.zorgkantoorregionaam)
  //           this.verhoudingen = response.data.map(d => d.verhouding)
  //           this.load.graphOne = true
  //           }
  //         ),
  //         this.$http.get('/api/zorgkantoor_verpleeghuisplekken/get_data_gemeentecode/').then(
  //           (response) => {
  //             this.location_factors = response.data.map(d => d.gemeentecode)
  //             this.location_names = response.data.map(d => d.zorgkantoorregionaam)
  //             this.factors = response.data.map(d => (d.verhouding).toFixed(2))
  //             this.load.graphTwo = true
  //           }
  //         )
  // }
}

</script>

<style scoped>

.intro-section-title{
  font-weight: bold;
  margin-bottom: 2rem;
}

</style>
